.popUpContainer{
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.popUp{
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    padding: 1.3vw;
    width: 30%;
    background: #FFFFFF;
}
.greyBack{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}
.exitRow{
    display: flex;
    align-items: center;
    justify-content: end;
}
.exitRow img{
    width: 0.83vw;
    height: 0.83vw;
    cursor: pointer;
}
.exitRow .simple_btn{
    width: 10vw;
    line-height: 1.25vw;
    padding: 0.5vw;
    box-sizing: border-box;
}
.popText{
    font-style: normal;
    font-weight: 400;
    font-size: 1.19vw;
    line-height: 1.6vw;
    margin-bottom: 1.25vw;
    color: #061423;
}
.editPopContainer{
    display: flex;
    flex-direction: column;
}
.editPopRow{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1vw;
    margin-bottom: 0.78vw;
}
.editPopUp{
    width: 46.875vw;
}

.mapContainer{
    width: 100%;
    height: 20vw;

}
.addressString{
    min-height: 3.5vw;
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: space-between;
    font-size: 0.93vw;
    line-height: 1.24vw;
}
.addressString .simple_btn{
    width: 3vw;
    padding: 0.125vw 0.5vw;
    font-size: 0.83vw;
    line-height: 0.93vw;
}

.popup_input{
    border: none;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.93vw;
    line-height: 1.24vw;
    border-bottom: 1px solid;
}
.popup_input:focus-visible{
    outline: unset;
}
.react-datepicker-popper{
    z-index: 3;
}

.addressString .popup_input{
    width: 70%;
}

.drop-vars-container{
    position: absolute;
    top: 3.5vw;
    left: 6.5vw;
    background: #FFFFFF;
    width: 38vw;
    max-height: 8vw;
    height: fit-content;
    z-index: 5;
    overflow-y: scroll;
    border-radius: 0 0 10px 10px;
    overflow-x: hidden;
}
.drop-var-item{
    padding: 0.5vw;
    width: 100%;
    overflow-x: scroll;
    cursor: pointer;
    white-space: nowrap;
}
.drop-var-item:hover{
    background: #d4e7ff;
}
@media screen and (max-width: 768px) {
    .popUp{
        padding: 18px;
        width: 90%;
    }
    .editPopUp {
        width: 89.875vw;
    }
    .mapContainer{
        height: 55vh;
    }
    .addressString{
        min-height: 35px;
        font-size: 16px;
        line-height: 18px;
    }
    .addressString .simple_btn{
        width: 125px;
        padding: 4px 0px;
        font-size: 16px;
        line-height: 18px;
    }
    .popup_input{
        font-size: 16px;
        line-height: 18px;
    }
    .drop-vars-container{
        top: 4vh;
        left: 0vw;
        width: 100%;
        max-height: 40vh;

    }
    .drop-var-item{
        padding: 5px;
    }
    .popText{
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 20px;
    }
    .exitRow .simple_btn{
        width: 100%;
        line-height: 25px;
        padding: 4px;
        box-sizing: border-box;
    }
    .exitRow img{
        width: 16px;
        height: 16px;
    }
    

}