.partner_page_container{
    margin-top: 20px;
}
.simple_text{
    font-size: 26px;
    margin-block-end: 1em;
    font-weight: 500;
}
.partner_table{
    overflow-x: scroll;
    margin-bottom: 20px;
    width: 100%;

}
.partner_tickets_tr {
    display: grid;
    grid-template-columns: 150px 150px 200px 200px 200px 100px 100px;
    align-items: center;
    cursor: pointer;
}
.partner_tickets_tr:nth-child(2n){
    background-color: #f7f7f7;
}
.partner_tickets_tr_header{
    font-weight: 600;
}
.partner_tickets_td{
    padding: 15px 10px;
    font-size: 16px;
}