.hard_input_container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.1vw 1.3vw;
    border-radius: 80px;
    border: 1px solid #004B8D;
    position: relative;
  }
  .hard_input_title{
    font-style: normal;
    font-weight: 400;
    font-size: 0.83vw;
    line-height: 1.1vw;
    color: #061423;
    background: #FFFFFF;
    padding: 0 0.7vw;
    position: absolute;
    top: -0.41vw;
    left: 0;
    white-space: nowrap;
  }
  .hard_input{
    width: 100%;
    border: none !important;
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    line-height: 1.4vw;
    color: rgba(6, 20, 35, 0.7);
  }
  .hard_input:focus-visible{
      outline: none !important;
  }
  @media screen and (max-width: 768px){
    .hard_input_title{
      font-size: 14px;
      line-height: 16px;
      top: -7px;
      padding: 0 7px;
    }
    .hard_input{
      font-size: 16px;
      line-height: 18px;
    }
    .hard_input_container{
      padding: 16px 20px;
    }
    
  }