.adminSet_inputsContainer{
    width: 39vw;
}


.adminSet_row{
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    line-height: 1.4vw;
    color: #061423;
}
.blue{
    color: #004B8D !important;
}

@media screen and (max-width: 768px){
    .adminSet_inputsContainer {
        width: 100%;
        margin-bottom: 30px;
    }
    .adminSet_row{
        font-size: 16px;
        line-height: 18px;
    }
    .adminSet_inputsContainer .column-2{
      grid-template-columns: repeat(1, 1fr);
    }
    .adminSet_inputsContainer .column-3{
        grid-template-columns: repeat(1, 1fr);
      }
    .adminSet_inputsContainer .column-5{
      grid-template-columns: repeat(1, 1fr);
    }
}