.depListContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2vw;
    overflow-x: scroll;
}
.depListContainer::-webkit-scrollbar{
    height: 3px;
    width: 3px;
    background-color: #FFFFFF;
}
.depListContainer::-webkit-scrollbar-thumb{
    background-color: #b1b3b4;
    border-radius: 5px;
}
.dep_item_grey{
    background: #F6F6F6 !important;
}
.dep_header{
    background: white !important;
    font-style: normal;
    font-weight: 500;
    color: #061423;
}
.dep_item{
    padding: 20px 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9375vw;
    line-height: 1.25vw;
    color: #061423;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 130vw;

}
.depContainer{
    padding-top: 2.6vw;
}

@media screen and (max-width: 768px){
    .depContainer{
        padding-top: 30px;
    }
    .depListContainer{
        margin-top: 10px;
        overflow-x: scroll;
    }
    .dep_item{
        padding: 10px 0px;
        font-size: 14px;
        line-height: 16px;
        width: 200vw;
    }
    .dep_item div{ 
        padding-right: 5px;
    }
}