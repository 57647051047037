.pointContainer{
    padding-top: 2.6vw;
}
.pointNavContainer{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 13.12vw;
    margin-bottom: 2.08vw;
}
.simple_header{
    color: var(--black, #061423);
    font-family: Uni Neue;
    font-size: 1.87vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.pointFilterContainer{
    display: flex;
    gap: 4vw;
}
.pointFilterText{
    font-size: 1.19vw;
    font-weight: 500;
}
.pointTypeFilter{
    display: flex;
}
.pointTypeFilterItem{
    padding: 0px 1.04vw 0.31vw 1.04vw;
    color: #061423;
    text-align: center;
    font-family: Uni Neue;
    font-size: 1.19vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px solid #CAE3F6;
    cursor: pointer;
}
.pointTypeFilterItemActive{
    border-bottom: 1px solid #004B8D;
}
.poinTableHeaderItem{
    color: var(--black, #061423);
    font-family: Uni Neue;
    font-size:  0.93vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
    display: flex;
    gap: 0.41vw;
    align-items: center;
}
.pointTable{
    width: 100%;
    box-sizing: border-box;
    position: relative;
}
.pointTableTr{
    display: grid;
    grid-column-gap: 7.57vw;
    color: var(--black, #061423);
    font-family: Uni Neue;
    font-size:  0.93vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    grid-template-columns: 8.5vw 7.81vw 7.81vw 7.81vw;
    padding: 1.04vw 2.08vw;
}
.redIncome{
    color: #f26262;
}
.pointTableHeader{
    padding:  0.62vw 2.08vw;
}
.pointTableTr:nth-child(1){
    background-color: transparent;
}
.pointTableTr:nth-child(3){
    background-color: transparent;
}
.pointTableTr:nth-child(2n){
    background-color: #F6F6F6;
}
.pointHeaderOpenFilter{
    cursor: pointer;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    display: none;
}
.react-datepicker__header{
    background-color: white;
}
.greenIncome{
    color: #69A042 !important;
}
.pointNavAnlitics{
    display: flex;
    gap: 2vw;
    margin-bottom: 2vw;
    font-size: 0.95vw;
}
.pointPeriodFilter{
    display: flex;
    gap: 10px;
}
.mainPointDateFilter:focus-visible{
    outline: none;
}
.mainPointDateFilter{
    border: 1px solid #061423;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 0.2vw 1vw;
    width: 12vw;
    cursor: pointer;
    color: #004b8d;
}
@media screen and (max-width: 768px) {
    .pointNavContainer{
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        margin-bottom: 4.08vw;
    }
    .pointTypeFilterItem{
        font-size: 4.19vw;
    }
    .pointTable{
        overflow-x: scroll;
    }
    .pointTableTr{
        grid-column-gap: 13.57vw;
        grid-template-columns: 60px 90px 60px 90px;
        font-size: 2.3vw;
        width: max-content;
    }
    .poinTableHeaderItem{
        font-size: 2.3vw;
        gap: 1.41vw;
    }
    .pointHeaderOpenFilter{
        width: 3vw;
    }
}