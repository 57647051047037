.bnv1{
    padding: 20px 60px;
    margin-bottom: 130px;
}
.bnv1>.title{
    font-size: 14px;
    margin: 10px 0px;
}
.special_no_mercy{
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
}