.adminsListContainer{
    margin-top: 2.6vw;
}
.admins-table{
    margin-top: 2vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    overflow-x: scroll;
}
.admins-table-second{
    background: #F6F6F6;
}
.admins-table-tr{
    display: grid;
    align-items: center;
    width: 100vw;
    box-sizing: border-box;
    padding-top: 1.25vw;
    padding-bottom: 1vw;
    padding-left: 1.5vw;
}
.admin-table-btnsContainer{
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 2.6vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-table-btnsContainer .simple_btn{
    margin-right: 1vw;
    width: fit-content;
    padding: 1vw 2vw;
}
.admins-table-list{
    grid-template-columns: 2fr 10fr 10fr 10fr 2fr 10fr;
}
.admins-table-tr{
    font-style: normal;
    font-weight: 400;
    font-size: 0.93vw;
    line-height: 1.25vw;
    color: #061423;
}
.admins-table-head{
    border-bottom: 1px solid rgba(177, 179, 180, 0.5);
    font-style: normal;
    font-weight: 500;
}
@media screen and (max-width: 768px){
    .admins-table{
        overflow-x: scroll;
    }
    .admins-table-tr{
        width: 250vw;
        font-size: 14px;
        line-height: 16px;
    }
    .adminsListContainer{
        margin-top: 30px;
    }
    .adminsListContainer .simple_btn{
        font-size: 12px;
        line-height: 14px;
        padding: 10px 20px;
    }
    .enveloper_center .nav-item{
        margin-right: 5px;
    }
}