.tickets .admins-table-tr{
    width: 72vw;
}
.tickets .admins-table{
    overflow-x: hidden;
}
.tickets .admins-table-list{
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr 1fr;
}
.tickets .admins-table-tr img{
    width: 1.5vw;
}
.nav-btn-wrapper .simple_btn{
    padding: 0.35vw 0.5vw;
    font-size: 1vw;
    line-height: 1vw;
    margin-right: 1vw;
    width: 5vw;
}
.nav_simple_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.simple_radio{
    width: 1vw;
    height: 1vw;
    border: 1px solid;
    margin-right: 0.5vw;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.simple_radio_active .simple_radio_center{
    width: 60%;
    height: 60%;
    background: #004b8d;
    border-radius: 15px;
}
.simple_btn_tr{
    cursor: pointer;
}
.ticket_pagination_container .simple_btn{
    padding: 0vw 0;
    margin: 1vw;
    width: 13%;
    font-size: 14px;
}
.ticket_pagination_container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.tickets .simple_input{
    font-size: 0.75vw;
    padding: 0vw 1.3vw;
    width: 20vw;
    margin-bottom: 0;
    margin-right: 1vw;
}