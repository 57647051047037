.partnerHeader{
    box-sizing: border-box;
    padding: 1vw 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(177, 179, 180, 0.5);
}
.navigation{
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-item{
    font-style: normal;
    font-weight: 400;
    font-size: 0.93vw;
    line-height: 1.25vw;
    text-align: center;
    color: #061423;
    padding-bottom: 0.2vw;
    box-sizing: border-box;
    width: 13.4vw;
    cursor: pointer;
    text-decoration: none;
}
.nav-item_active{
    border-bottom: 1px solid #004B8D;
}
.header_dropContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.header_drop_prev{
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    line-height: 1.4vw;
    color: #061423;
    cursor: pointer;
}
.header_drop{
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(6, 20, 35, 0.15);
    position: fixed;
    top: 4vw;
    right: 13vw;
    padding: 1vw 1.25vw;
    min-width: 6vw;
    z-index: 10;
}
.header_drop_item{
    display: flex;
    align-items: center;
    margin-bottom: 0.5vw;
    font-style: normal;
    font-weight: 500;
    font-size: 0.93vw;
    line-height: 1.25vw;
    color: #061423;
    cursor: pointer;
}
.header_drop_item img {
    margin-right: 0.3vw;
    width: 1vw;
}
.header_drop_item_red{
    color: #D62D30 !important;
}
.mob_header_2{
    display: flex;
    gap: 1vw;
}
.new_page_wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
}
.main_title{
    font-size: 25px;
}

@media screen and (max-width: 768px) {
    .partnerHeader{
        padding: 4vw 0;
        flex-wrap: wrap;
        row-gap: 4vw;
        align-items: center;
        flex-direction: column-reverse;
    }
    .navigation{
        padding-right: 10px;
        justify-content: space-around;
        width: 100%;
    }
    .nav-item{
        font-size: 4vw;
        line-height: 4vw;
        padding-bottom: 4px;
        text-align: center;
        width: unset;
    }
    .header_drop_prev{
        gap: 8px;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        padding-bottom: 4px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .userLogo{
        width: 20px;
    }
    .header_drop{
        top: 44px;
        width: 100vw;
        left: 0;
        padding: 15px 15px; 
    }
    .header_drop_item{
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
    }
    .header_drop_item{
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
    }
    .header_drop_item img{
        margin-right: 5px;
        width: 10px;
    }
    .mob_header_2{
        display: flex;
        gap: 8vw;
        align-items: center;
        font-size: 3.9vw;
    }
    .vert_hr{
        height: 4vw;
    }
}