.userPointTotalCotainer{
    color:  #004B8D;
}
.policy_accept_container{
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
    height: 10vh;
    gap: 2vw;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid rgba(177, 179, 180, 0.5);
    padding-left: 40px;
}
.policy_accept_container .simple_btn{
    width: 11vw;
    height: fit-content;
    padding: 0.5vw;
}
.policy_accept_text{
    font-size: 1.2vw;
    font-weight: 600;
}
.policy_accept_text a{
    text-decoration: underline;
    color: #004887;
}
.policy_accept_text a:visited{
    text-decoration: underline;
    color: #004887;
}
@media screen and (max-width: 768px) {
    .policy_accept_container{
        flex-direction: column;
        gap: 3vw;
        height: 28vh;
        padding: 15px 6vw;
        padding-bottom: 20px;
    }
    .policy_accept_container .simple_btn{
        width: 40vw;
        height: fit-content;
        padding: 2vw;
        box-sizing: border-box;
    }
    .policy_accept_text{
        font-size: 16px;
    }
}


.partner_app_tr {
    display: grid;
    grid-template-columns: 150px 150px 200px 200px 200px 100px 100px;
    align-items: center;
}
.partner_app_tr:nth-child(2n){
    background-color: #f7f7f7;
}
.partner_app_tr_header{
    font-weight: 600;
}
.partner_app_td{
    padding: 15px 10px;
    font-size: 16px;
}
.partner_filter_wrapper{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.partner_main_wrapper{
    display: flex;
    box-sizing: border-box;
    padding: 40px 0;
    flex-direction: column;
    gap: 20px;
}
.simple_row{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.partner_watch{
    background: linear-gradient(45deg, #004b8d, #00315d);
    color: white;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-sizing: border-box;
    width: 250px;
}
.partner_watch>.ititle{
    font-size: 18px;
    font-weight: 600;
}
.section_block{
    box-sizing: border-box;
    width: 100%;
    background: #003869;
    border-radius: 8px;
    padding: 0;
    color: white;
    height: 120px;
    display: flex;
    padding-left: 0;
    cursor: pointer;
}
.section_block>.back{
    background-repeat: no-repeat;
    width: 220px;
    height: 70%;
    margin-top: 15px;
    background-position: center;
    background-position-x: center;
    background-size: contain;
    background-position-y: center;

}
.section_block>.column{
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;
    padding: 10px 0;
    padding-top: 25px;
}
.section_block>.column>.ititle{
    font-size: 22px;
    font-weight: 300;
}
.simple_section{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 10px 0;
}
.simple_section>.ititle{
    font-size: 22px;
}
.new_partner_item{
    border: 1px solid #d8d9d9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 320px;
    color: black;
}
.new_partner_item>.img{
    width: 100%;
    height: 180px;
    background-position: center;
    background-size: cover;
}
.new_partner_item>.icolumn{
    display: flex;
    padding: 15px 20px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 8px;
}
.new_partner_item>.icolumn>.simple_row{
    justify-content: space-between;
}
.new_partner_item>.icolumn>.simple_row>.ititle{
    font-size: 18px;
}
.new_partner_item>.icolumn>.simple_row>.isubtitle{
    font-size: 12px;
}
.sac1{
    align-self: center;
}
.standart_btn{
    color: #004887 !important;
    font-size: 16px;
    transition: all 0.5s;
    cursor: pointer;
}
.standart_btn:hover{
    text-decoration: underline !important;
}
.sssss3{
    font-size: 28px !important;
    margin-top: 20px;
}
.new_wrapper{
    display: flex;
    gap: 40px;
}
.ssssss1 .modal_pre_image{
    max-width: 300px;
    width: 300px;
}
.ssssss2{
    font-size: 18px;
    line-height: 28px;
}
.ssss4{
    font-size: 16px;
    margin-left: 40px;
}
.faq_item_category{
    font-size: 20px;
    padding: 15px 0;
    border-bottom: 2px solid #d8d9d9;
    transition: all 0.7s;
    padding-left: 0px;
    cursor: pointer;
    color: black;
    display: block;
}
.faq_item_category:hover{
    padding-left: 10px;
}
.faq_page{
    flex-direction: row;
}
.faq_nav{
    width: 280px;
    padding: 15px 25px;
    border: 1px solid var(--back);
    border-radius: 8px;
    box-shadow: 1px 1px 3px 0px #0000000f;
}
.faq_main{
    padding: 25px 25px;
    border: 1px solid var(--back);
    border-radius: 8px;
    box-shadow: 1px 1px 3px 0px #0000000f;
    width: 100%;
    height: fit-content;
}
.faq_main>.ititle{
    font-size: 22px;
    margin-bottom: 15px;
}
.faq_main>.itext{
    font-size: 16px;
}
.faq_nav>.category{
    width: 100%;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 400;
    color: #3b3b3b;
}
.faq_nav>.category:hover{
    text-decoration: underline;
}
.faq_nav>.subcategory{
    font-size: 13px;
    margin-bottom: 2px;
    display: block;
    padding-left: 13px;
    color: #3b3b3b;
}
.faq_nav>.subcategory:hover{
    text-decoration: underline;
}
.live_chart_fly{
    position: fixed;
    bottom: 20px;
    right: 55px;
    z-index: 100;
    
    width: 360px;
    display: flex;
    justify-content: flex-end;
}
.rowv{
    background: white;
    
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--back);
    box-shadow: 1px 1px 3px 0px #0000000f;
    padding: 13px 20px;
}
.fuq_row{
    display: flex;
    gap: 10px;
}
.fuq_col{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.bbn1{
    margin-block-end: 1px;
    font-size: 14px;
}
.bbn3{
    display: flex;
    gap: 10px;
    align-items: center;
}
.bbn4{
    gap: 5px;
    display: flex;
    align-items: center;
}
.bbn4>.round{
    width: 10px;
    height: 10px;
}
.bbn5{
    height: 35px;
}
.bbn6{
    height: 100%;
    box-sizing: border-box;
}
.bbn2{
    position: absolute;
    right: -11px;
    top: -13px;
    border: 1px solid;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: white;
    cursor: pointer;
}
.message_wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    padding: 10px;
    background: #f7f7f7;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #c2c2c2;
}
.message_drive_wrapper{
    width: 100%;
}
.message{
    width: fit-content;
    box-sizing: border-box;
    padding: 5px 35px;
    font-size: 12px;
    max-width: 85%;
    padding-bottom: 7px;
}
.message_from_admin{
    align-self: flex-start;
    background: #004a8c;
    color: white;
    border-radius: 8px 8px 8px 0px;
}
.message_from_user{
    align-self: flex-end;
    background: #007cc2;
    color: white;
    border-radius: 8px 8px 0px 8px;
}
.partner_footer{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
}
.partner_footer>.col{
    display: flex
    ;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
}
.right_fot_col{
    align-items: flex-end !important;
}
.ceter_foot_col{
    flex-direction: row !important;
}
.map_main_wrapper{
    width: 100%;
    height: 400px;
}
.page_bvn2{
    padding: 40px 0;
}
.page_bvn2>.ititle{
    color: black;
    font-size: 22px;
    margin-bottom: 20px;
}
.bvn2_cards{
    display: flex
;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 50px;
}
.bvn2_card{
    display: flex
    ;
        flex-direction: column;
        gap: 5px;
}
.bvn2_card>.ititle{
    font-size: 18px;
}
.bvn2_card>.subtitile{
    display: flex
    ;
    flex-direction: column;
        gap: 15px;
        row-gap: 5px;
        flex-wrap: wrap;
        font-size: 14px;
        max-width: 300px;
}
.bvn2_card>.subsubtitle{
    font-size: 14px;
}
.hstack_fnck{
    align-items: flex-start;
    flex-flow: wrap;
    gap: 10px;
    height: 320px;
    display: flex;
}
.partn_calendar{
    width: 50%;
}
@media screen and (max-width: 768px) {
    .partner_watch{
        width: 100%;
      }
      .partner_watch>.ititle{
        font-size: 22px;
      }
      .section_block{
        flex-direction: column;
        height: fit-content;
      }
      .section_block>.back{
        width: 100%;
        height: 80px;
        display: none;
      }
      .section_block>.column{
        padding: 20px 30px;
      }
      .section_block>.column>.ititle{
        font-size: 24px;
      }
      .section_block>.column>.isubtitle{
        font-size: 18px;
      }
      .simple_section>.ititle{
        font-size: 28px;
      }
      .new_partner_item{
        width: 100%;
      }
      .a_partner_watch{
        width: 100%;
      }
      .a_new_partner_item{
        width: 100%;
      }
      .new_wrapper{
        flex-direction: column;
      }
      .ssssss1 .modal_pre_image{
        max-width: 100%;
        width: 100%;
      }
      .faq_page{
        flex-direction: column;
      }
      .faq_nav{
        width: 100%;
        box-sizing: border-box;
      }
      .faq_main{
        box-sizing: border-box;
      }
      .faq_main>.itext{
        font-size: 16px;
      }
      .live_chart_fly{
        bottom: 10px;
        right: 20px;
        width: 90vw;
      }
      .message_wrapper{
        height: 60vh;
      }
      .partner_footer{
        flex-direction: column-reverse;
        gap: 30px;
        padding-bottom: 100px;
      }
      .ceter_foot_col{
        flex-direction: column !important;
        align-items: flex-end !important;
      }
      .bnv1{
        padding: 20px 0 !important;
        margin-bottom: 50px;
      }
      .partn_calendar{
        width: 100%;
      }
      .hstack_fnck{
        height: fit-content;
      }
      .right_fot_col{
        /* align-items: flex-start !important; */
      }
}