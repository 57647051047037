.setContainer{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 2.9vw;
}
.setCont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    padding: 2vw;
    box-sizing: border-box;
}
.selfSetContainer{
    width: 42.18vw;
}
.setTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    line-height: 1.4vw;
    text-align: center;
    color: #004B8D;
    margin-bottom: 1.3vw;
}
.setRow{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.passSetContainer{
    width: 29.68vw;
}
.setRow .simple_input{
    width: 49%;
}
@media screen and (max-width: 768px){
    .setContainer{
        margin-top: 30px;
        flex-wrap: wrap;
    }
    .selfSetContainer{
        width: 100%;
    }
    .passSetContainer{
        width: 100%;
    }
    .setCont{
        height: unset;
        padding: 15px;
        margin-bottom: 20px;
    }
    .setTitle{
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 18px;
    }
    
}