.App {
  box-sizing: border-box;
  padding: 0 13.3vw;
}
.App *{
  font-family: 'Uni Neue';
}
.header_img{
  width: 4.4vw;
  height: auto;
}
.simple_input{
  background: #FFFFFF;
  border: 1px solid #004B8D;
  border-radius: 220px;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.4vw;
  color: rgba(6, 20, 35, 0.7);
  padding: 1.09vw 1.3vw;
  margin-bottom: 0.78vw;
  width: 100%;
  box-sizing: border-box;
}
.forget_text{
  font-weight: 400;
  font-size: 0.93vw;
  line-height: 1.25vw;
  color: #061423;
  border-bottom: 1px dashed black;
}
.cursorPointer{
  cursor: pointer !important;
}
.simple_btn{
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 1.19vw;
  line-height: 1.6vw;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-content: center;
  justify-content: center;
  background: #004B8D;
  border-radius: 170px;   
  padding: 1vw 0;
  cursor: pointer;
}
.simple_header{
  font-style: normal;
  font-weight: 500;
  font-size: 1.875vw;
  line-height: 2.55vw;
  color: #061423;
}
.sort_filterContainer{
  display: flex;
  align-items: center;
}
.sort_filte_img{
  width: 0.7vw;
  margin-left: 0.3vw;
  margin-top: 0.2vw;
}
.mt-15{
  margin-top: 0.78vw;
}
.mb-15{
  margin-bottom: 0.78vw;
}
.mb-40{
  margin-bottom: 2vw;
}
.mt-50{
  margin-top: 2.6vw;
}
.simple_textarea{
  resize: none;
  height: 7.29vw;
  width: 100%;
  padding: 0.78vw 1vw;
  font-style: normal;
  font-weight: 400;
  font-size: 0.93vw;
  line-height: 1.25vw;
  color: rgba(6, 20, 35, 0.7);
  border: 1px solid #004B8D;
  border-radius: 3px;
  position: relative;
  box-sizing: border-box;
}
.simple_hint_wrapper{
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 2;
}
a:visited{
  color: inherit;
  text-decoration: none;
}
.relative{
  position: relative;
  width: 100%;
}
a:-webkit-any-link {
  text-decoration: none;
}
.small_text{
  font-size: 10px;
}
.between_row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.simple_btn_small{
  width: fit-content;
  padding: 0.625vw 1.5vw;
  font-size: 0.93vw;
  line-height: 1.25vw;
}
.center_row{
  display: flex;
  justify-content: center;
  align-items: center;
}
.left_row{
  display: flex;
  justify-content: left;
  align-items: center;
}
.simple_btn_grey{
  background: #F6F6F6;
  color: #061423;
}
.simple_file{
  margin-bottom: 0.83vw;
  font-size: 1.1vw;
  line-height: 1.3vw;
  box-sizing: border-box;
  padding: 1.25vw;
  background: #F6F6F6;
  color: #061423;
  border-radius: 35px;
  cursor: pointer;
}
.doc_link{
  font-style: normal;
  font-weight: 400;
  font-size: 0.93vw;
  line-height: 1.25vw;
  color: #061423;
  border-bottom: 1px dashed #061423;
}
.enveloper_center{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 8px;
}
.simple_note{
  font-size: 0.83vw;
  line-height: 0.93vw;
}
.column-5{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0.78vw;
}
.column-3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0.78vw;
}
.column-2{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0.78vw;
}
.adminSet_row img{
  width: 0.8vw;
  margin-left: 0.4vw;
  cursor: pointer;
}
.no_pointer{
  cursor: default !important;
}
.pointer{
  cursor: pointer !important;
}
input[type="file"] {
  display: none;
}
.invisible_input{
  border: 1px solid #061423;
  background: #FFFFFF;
  border-radius: 5px;
  border-bottom: 1px solid #061423;
  padding: 0.25vw 0.75vw;
}
.invisible_input:focus-visible{
  outline: none;
}
.invisible_input:disabled{
  color: #061423;
}

.yaMap{
  width: 100%;
}

.mobile{
  display: none !important;
}
.not_mobile{
  display: flex !important;
  align-items: center;
  gap: 0.4vw;
}
.simple_input_wrapper{
  position: relative;
}
.simple_input_title{
  background-color: #FFFFFF;
  position: absolute;
  left: 0.75vw;
  top: -0.55vw;
  font-size: 0.83vw;
  padding: 0 0.25vw;
}
.simple_scroll::-webkit-scrollbar{
  height: 3px;
  width: 3px;
  background-color: #FFFFFF;
}
.simple_scroll::-webkit-scrollbar-thumb{
  background-color: #b1b3b4;
  border-radius: 5px;
}
.mb-2{
  margin-bottom: 2vw !important;
}
.hr{
  width: 100%;
  height: 1px;
  background-color: rgba(177, 179, 180, 0.50);
}
.vert_hr{
  width: 1px;
  height: 1vw;
  background-color: rgba(177, 179, 180, 0.50);
}
.delimetr{
  margin: 0 10px;
}
.bold{
  font-weight: 600;
}
.green_text{
  color: #009700;
}
.red_text{
  color: rgb(133, 0, 0);
}
.round{
  width: 15px;
  height: 15px;
  border-radius: 200px;
}
.green{
  background: #009700;
}
.red{
  background: rgb(133, 0, 0);
}
.unpanel_header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.simple_page_wrapper{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.simple_small_btn{
  font-size: 16px;
  cursor: pointer;
}
.simple_small_btn:hover{
  text-decoration: underline;
}

/*timeline*/
.pm_timeline_wrapper{
  display: flex;
  gap: 10px;
  align-items: center;
}
.pm_timeline_round{
  width: 50px;
  height: 50px;
  border: 2px solid #004b8d;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #003564;
}
.pm_timeline_item{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  gap: 5px;
}
.pm_timeline_hr{
  height: 2px;
  width: 60px;
  background: #004b8d;
  margin-block-end: 15px;
  border-radius: 5px;
}
.pm_timeline_title{
  font-weight: 600;
}
.simple_page_wrapper{
  padding-top: 20px;
}
.unpanel_header img{
  width: 60px;
}
.application_title{
  font-size: 26px;
}
.application_wrapper{
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 40px;
}
.input_wrapper{
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.pm_btn{
  background: #004b8d;
}
.pm_btn:hover{
  background: #003d72;
}
.pm_timeline_active .pm_timeline_round{
  background: #003d72;
  color: white;
}
.uploader_area_wrpapper{
  width: 100%;
  height: 120px;
  border: 1px dashed rgba(0, 0, 0, 0.11);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
}
.uploader_area_wrpapper:hover{
  border: 1px dashed rgba(0, 0, 0, 0.418);
}
.uplaoder_success_image{
  font-size: 20px;
  color: green;
}
.uploader_img{
  max-width: 120px;
  border-radius: 5px;
}
.uploader_area_wrpapper_success{
  height: 60px;
}
.success_uploader_wrapper{
  display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}
.success_img_uploader_wrapper{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.uploader_img_wrapper{
  position: relative;
}
.uploader_img_wrapper:hover::before{
  content: "";
    background: black;
    opacity: 0.4;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 5px;
    z-index: 1;
}
.uploader_input_wrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.uploader_img_wrapper .uploader_img_axit{
  display: none;
}
.uploader_img_wrapper:hover .uploader_img_axit{
  display: block;
  position: absolute;
  z-index: 2;
  right: 5px;
  top: 5px;
  color: white;
  font-size: 16px;  
  cursor: pointer;
}
.input_wrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.input_wrapper_row{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.input_title{
  font-size: 16px;
}
.aplication_subtitle{
  text-transform: uppercase;
  font-size: 18px;
  margin: 15px 0;
}
.modal_images_pre_wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.aplication_wrong_text{
  color: red;
  font-size: 16px;
}
.wrong_input{
  border: 1px solid red;
}
.wrong_input {
  border: 0px solid red;
  border-bottom: 1px solid red;
}
.apl_track_title{
  font-size: 20px;
}
.apl_track_wrapper{
  display: flex;
  align-items: flex-start;
  margin: 30px 0;
  flex-wrap: wrap;
  row-gap: 20px;
}
.apl_track_item{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.apl_track_img_wrapper{
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: black;
  width: 120px;
  padding: 10px 10px;
  border-bottom: 2px solid transparent;
}
.apl_track_text{
  font-size: 14px;
}
.apl_track_item_active .apl_track_img_wrapper{
  border-bottom: 2px solid #004b8d;
}
.modal_pre_image{
  cursor: pointer;
  width: 100%;
  max-width: 100px;
  border-radius: 5px;
}
.image_modal_modal{
  width: 70vw;
}
.modal_body_img{
  /* width: 100%; */
  user-select: none;
}
.w-25{
  width:10vw; 
}
.apl_track_page_wrapper{
  border: 1px solid #004b8d;
  padding: 30px 40px;
  margin-top: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.imag_application_example_wrapper{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.img_application_example{
  max-width: 320px;
}
.message_panel{
  height: 40vh;
  width: 25vw;
  overflow-y: scroll;
}
.values_panel_update{
  overflow-y: scroll;
  height: 60vh;
}
.message_panel hr{
  margin-bottom: 10px;
  margin-top: 10px;
}
.scroll_stylle::-webkit-scrollbar {
  width: 2px;
  border-radius: 20px;
  height: 2px;
}
.scroll_stylle::-webkit-scrollbar-thumb {
  background-color: #3B3D3E;
  border-radius: 20px; 
}
.wdsa1{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}
.wdsa2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 40vw;
  overflow-y: scroll; 
  max-height: 80vh;
  padding-bottom: 4vh
}
.system_msg_wrapper{
font-size: 11px;
  text-align: left;
  white-space: pre-wrap;
  overflow: hidden;
  transition: all 1s;
}
.system_msg_wrapper_wrapped{
max-height: 15px;
}
.system_msg_wrapped_btn{
font-size: 11px;
cursor: pointer;
text-align: right;
}
.simple_message_panel{
  border: 1px solid rgba(0, 0, 0, .06);
  text-align: left;
  padding: 0.5vw;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 0.3vw;
}
.simple_message_panel_title_title{
  font-weight: 600;
}
.simple_message_panel_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal_application_update{
  width: fit-content;
}
.docs_page{
  padding-top:60px;
}
.docs_title{
  margin-block-end: 30px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.simple_docs_subtitle{
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
}
.ttz101{
  font-size: 18px;
  margin-block-end: 20px;
}
.w-100{
  width: 100% !important;
}
.red_bad_div{
  color: white;
  border-radius: 25px;
  padding: 10px 25px;
  box-sizing: border-box;
  margin-bottom: 10px;
  background-color: red;
}
.img_modal_flight{

  width: 65px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;

}
.zzio1{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img_modal_modal_www{
  width: 100%;
  max-width: 100%;
  max-height: 70vh;
  overflow: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.real_image_modal_flight{
  position: absolute;
  right: 0;
  top: 0;
  width: 63px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.289);
  z-index: 2;
  border-radius: 0 0 0 5px;
  color: white;
}
.anal777{
  display: flex;
    column-gap: 15px;
    row-gap: 3px;
    flex-wrap: wrap;
    align-items: center;
}
.anal778{
  margin-bottom: 20px;
}
.zzo90{
  display: flex;
  align-items: center;
  gap: 5px;
}
.anal_round{
  width: 7px;
  height: 7px;
  border-radius: 200px;
  margin-right: 5px;
}
.bg_blue{
  background: #004B8D !important;
}
.adminChatWithPartnersWrapper{
  display: flex;
      margin-top: 50px;
}
.chat_choose_admin_wrapper{
  width: 320px;
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 85vh;
}
.admin_chat_item{
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #f7f7f7;
  padding: 10px 0;
  cursor: pointer;
  padding-right: 5px;
  padding-left: 5px;
}
.admin_chat_item_active{
  background: #f7faff;
}
.admin_real_chat_wrapper{
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 85vh;
}
.admin_real_chat_wrapper>.message_wrapper {
  flex: 1;
}
.admin_chat_header{
  margin-bottom: 0px;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}
.admin_chat_header>.otitle{
  font-size: 14px; 
}
.bbn51{
  height: 24px;
}
.bbn52{
  padding-right: 30px;
}
.admin_chat_item>.ititile{
  font-size: 14px;
}
.bbn7{
  display: flex
  ;
      justify-content: flex-end;
      gap: 10px;
      align-items: center;
      font-size: 12px;
}
.counter_chat_admin{
  box-sizing: border-box;
  padding: 1px 8px;
  border-radius: 8px;
  background: #004a8c;
  color: white;
  font-size: 9px;
  font-weight: 800;
}
.bbn9{
  padding: 20px 10px;
}
.ojbudda{
  display: flex;
  align-items: center;
  gap: 5px;
}
.wrapper_content_1{
  margin: 60px 0;
  display: flex
;
  flex-direction: column;
  gap: 15px;
  flex: 1;
}
.das_content_row{
  display: flex
  ;
  gap: 20px;
  margin: 60px 0;
}
.my_simple_row{
  display: flex
  ;
      align-items: center;
      gap: 15px;
}
.my_simple_row>.ptile{
  font-size: 25px;
}
.driver_item{
  display: grid
  ;
      align-items: center;
      gap: 10px;
      grid-template-columns: 130px 250px;
      margin-bottom: 15px;
}
.das_tr{
  display: grid
  ;
  padding: 13px 13px;
  text-align: left;
}
.das_header_tr{
  font-weight: 700;
  color: black;
  font-size: 16px;
}
.das_tr:nth-child(2n+1){
  border-bottom: 1px solid #dde2eb;
}
.driver_btns{
  display: flex
  ;
      align-items: center;
      gap: 15px;
      flex-wrap: wrap;
      margin-top: 20px;
}
.das_content_row>.wrapper_content_1{
  margin: unset;
}
@media screen and (max-width: 768px) {
  .mobile{
    display: flex !important;
  }
  .mb-15{
    margin-bottom: 15px;
  }
  .not_mobile{
    display: none !important;
  }
  .header_img{
    width: 85px;
  }
  
  .simple_input{
    font-size: 16px;
    line-height: 24px;
    padding: 10px 25px;
    margin-bottom: 15px;
  }
  
  .forget_text{
    font-size: 14px;
    line-height: 22px;
  }
  
  .simple_header {
    font-size: 18px;
    line-height: 24px;
  }
  .simple_textarea{
    height: 8vh;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
  }
  .simple_btn{
    font-size: 18px;
    line-height: 20px;
    padding: 14px 0;
    box-sizing: border-box;
  }
  .App{
    padding: 0 9.3vw;
  }
  .mt-15{
    margin-top: 15px;
  }
  .simple_note{
    font-size: 16px;
    line-height: 18px;
  }
  .simple_update_modal_wrapper{
    flex-wrap: wrap !important;
  }
  .simple_file{
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 18px;
    box-sizing: border-box;
    padding: 7px;
  }
  .simple_header {
    font-size: 6vw !important;
  }
  .owl_message_wrapper{
    width: 100vw;
  }
  .owl_message_wrapper_item{
      width: 90%;
  }
  .message_panel{
      max-height: 50vh;
      overflow-y: scroll;
  }
  .mobile_expand{
    position: absolute;
    padding: 2vw;
    margin: 2vw;
    border-radius: 5px;
    background: white;
    bottom: 10px;
    left: 10px;
    box-shadow: var(--rs-panel-shadow);
    cursor: pointer;
    z-index: 0;
}

}