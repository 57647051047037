:root{
    --sub: #6f80aa;
    --main: #304db0;
    --mainhov: #00154e;
    --back: #E3E3E3;
    --shadow: 1px 1px 10px 3px #0000001f;
    --subback: #eeeeee;
  
  }

.header_wrapper{
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    max-width: 240px;
  }
  .main_side_nav{
    height: 100vh;
    background: var(--mainhov);
    color: white;
  }
  .ttz8{
    width: 100%;
    height: 40px;
    
    margin: 25px 0;
    margin-top: 30px;
    cursor: pointer;
  }
  .ttz7{
    background: var(--mainhov) !important;
    text-align: left;
  }
  .ttz7:hover{
    color: white !important;
    background: var(--main) !important;
  }
  .ttz9 button{
    background: var(--mainhov) !important;
  }
  .ttz9:hover button{
    color: white !important;
    background: var(--main) !important;
  }
  .ttz7.rs-sidenav-item-active{
    color: white !important;
    font-weight: 700;
  }


  @media screen and (max-width: 768px) {
    .header_wrapper{
    max-width: 100vw;
    left: -12vw;
    transition: all 0.2s;
  }
  .header_shift{
    left: 0;
  }
}
.uui1{
  color: red !important;
}