.checkBox_container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .checkbox{
    margin-right: 0.4vw;
    width: 0.98vw;
    height: 0.98vw;
    border: 1px solid #004B8D;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0.98vw;
    min-height: 0.98vw;
  }
  .checkBox-text{
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    line-height: 1.4vw;
    color: #061423;
  }
  .checkbox img {
    width: 60%;
  }
  @media screen and (max-width: 768px) {
    .checkbox{
      margin-right: 8px;
      width: 19px;
      height: 19px;
      min-width: 19px;
      min-height: 19px;
    }
    .checkBox-text{
      font-size: 14px;
      line-height: 16px;
    }
  }