.newDepContainer{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 2vw;
    margin-bottom: 1vw;
}
.newDepSetContainer{
    width: 31.25vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 7.8vw;
    box-sizing: border-box;
    border-right: 1px solid rgba(177, 179, 180, 0.5);
    justify-content: space-between;
}
.newDepSetItem{
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.5vw;
    color: #061423;
    display: flex;
    align-items: center;
    width: 100%;
}
.inputTrow{
    width: 100%;
    display: flex;
    align-items: center;
}
.inputTrow .invisible_input{
    width: 100%;
}
.newDepSetItem .invisible_input{
    font-size: 1.2vw;
    box-sizing: border-box;
    margin-left: 1vw;
}
.newDepSetItem img {
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
    margin-left: 0.5vw;
}
.blueText{
    color: #004B8D;
}

.radio-item{
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 1.5vw;

    color: #061423;
}
.simple-radio{
    width: 0.83vw;
    height: 0.83vw;
    border: 1px solid #004B8D;
    border-radius: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.41vw;
    cursor: pointer;
    box-sizing: border-box;
}
.simple-radio-active div{
    height: 60%;
    width: 60%;
    background: #004B8D;
    border-radius: 82px;
}
.depVariablesList{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 0 6.25vw;
    position: relative;
}
.newDepHeight{
    padding-right: 2.8vw;
    height: 53.46vw;
}
.newDepSetContainer .checkBox-text{
    font-size: 1.2vw;
    line-height: 1.4vw;
}
.newDepSetContainer .checkbox{
    margin-right: 1vw;
}
.newDepSetContainer .checkBox_container{
    margin: 1vw 0;
}
.dep-var-item{
    display: flex;
    width: 100%;
    padding-bottom: 1vw;
    border-bottom: 1px solid rgba(177, 179, 180, 0.5);
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1vw;
}
.dep-var-header{
    font-style: normal;
    font-weight: 500; 
    font-size: 1vw;
    line-height: 1.4vw;
    margin-bottom: 0.46vw;
    color: #004B8D;
}
.popAboutDepContainer{
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 8px;
}
.popAboutDepContainer div{
    margin-bottom: 5px;
}
.dep-var-postman{
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    line-height: 1.2vw;
    color: #061423;
    margin-bottom: 0.4vw;
}
.dep-var-item-text{
    font-style: normal;
    font-weight: 500;
    font-size: 1vw;
    line-height: 1.25vw;
    color: #061423;
    opacity: 0.9;
    margin-bottom: 0.4vw;
}
.dep-var-item-row{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}
.dep-var-item-column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.dep-var-item-column-wrapper-small{
    width: 3.9vw;
}
.dep-var-item-column-wrapper-small .simple_btn{
    font-size: 0.93vw;
    line-height: 1.25vw;
    padding: 0.46vw 0.83vw;
}
.dep-var-item-row-revert{
    display: flex;
    align-items: flex-end;
    justify-content: end;
    width: 100%;
}
.address_text_deps{
    text-decoration: underline;
    cursor: pointer;
}
.popRedText{
    color: red;
    font-size: 12px;
    margin: 5px 0;
    padding-right: 86px;
    line-height: 13px;
}
.popBlackText{
    color: black;
    font-size: 12px;
    margin: 5px 0;
    padding-right: 86px;
    line-height: 13px;
}
.newDepSetContainer .simple_header{
    margin-bottom: 1vw;
}
@media screen and (max-width: 768px) {
    .newDepContainer{
        margin-top: 30px;
        margin-bottom: 30px;
        flex-wrap: wrap;
    }
    .newDepHeight {
        height: 130vh;
    }
    .newDepSetContainer{
        width: 100%;
        border-right: none;
        padding-right: unset;
    }
    .newDepSetItem{
        font-size: 18px;
        line-height: 18px;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        row-gap: 2vw;
    }
    .inputTrow{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .newDepSetItem img{
        width: 25px;
        height: 25px;
        margin-left: 5px;
        
    }
    .newDepSetContainer .checkbox {
        margin-right: 6vw;
        width: 25px;
        height: 25px;
        min-width: 25px;
        min-height: 25px;
    }
    .newDepSetItem .invisible_input{
        font-size: 20px;
        width: 100%;
        margin-left: 0;
    }
    .newDepSetContainer .simple_textarea{
        height: 9vh;
        padding: 10px 12px;
        font-size: 16px;
        line-height: 18px;
        box-sizing: border-box;
    }
    .newDepSetContainer .small_text{
        font-size: 11px;
    }
    .newDepSetContainer .checkBox-text{
        font-size: 18px;
        line-height: 22px;
    }
    .newDepSetContainer .checkBox_container{
        margin: 3vw 0;
    }
    .simple-radio{
        width: 25px;
        height: 25px;
        margin-right: 15px;
    }
    .radio-item{
        font-size: 18px;
        line-height: 22px;
    }
    .depVariablesList{
        padding: unset;
        
        margin-top: 20px;
        position: relative;
        min-width: 100%;
    }
    .dep-var-postman{
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 8px;
    }
    .dep-var-item-text{
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 8px;
    }
    .dep-var-item-column-wrapper-small{
        width: 125px;
    }
    .dep-var-item-column-wrapper-small .simple_btn{
        font-size: 16px;
        line-height: 18px;
        padding: 10px 16px;
    }
    .dep-var-header{
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
    }
    .newDepSetContainer .simple_header{
        margin-bottom: 2vw;
    }
    .invisible_input{
        padding: 5px 10px;
    }
}