.additional_btn_container{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.additional_btn_container .simple_btn{
    width: 30%;
}
.partners-table-list{
    width: 100% !important;
    grid-template-columns: 1fr 5fr 5fr 5fr 3fr 1fr;
    /* grid-template-columns: 40px 160px 160px 160px 120px 40px; */
}