@-webkit-keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.sceleton{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    -webkit-animation: 1.5s shine linear infinite;
            animation: 1.5s shine linear infinite;
}