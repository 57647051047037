.drop_filterContainer{
    position: relative;;
}
.drop_filter{
    position: absolute;
    top: 2vw;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(6, 20, 35, 0.15);
    padding: 1.3vw;
    width: 12.5vw;
}
.drop_btn_text_dark{
    font-style: normal;
    font-weight: 500;
    font-size: 0.83vw;
    line-height: 1.14vw;
    color: #061423;
    cursor: pointer;
}
.drop_btn_text_grey{
    font-style: normal;
    font-weight: 500;
    font-size: 0.83vw;
    line-height: 1.14vw;
    text-align: right;
    color: rgba(6, 20, 35, 0.5);
    cursor: pointer;
}
.drop_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}