  .inviteHeader{
    width: 100%;
    padding-top: 1.3vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .inviteContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 17.4vh;
    margin-bottom: 10.4vh;
  }
  .invite_size{
    width: 26vw;
  }
  .invite_tabsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: 1.56vw;
  }
  .invite_tab{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid #CAE3F6;
    cursor: pointer;
    align-items: flex-start;
    justify-content: center;
    padding: 0.52vw 0.52vw;
    font-style: normal;
    font-weight: 500;
    font-size: 1.875vw;
    line-height: 2.55vw;
    text-align: center;
    color: #061423;
  }
  .invite_tab_active{
    border-bottom: 3px solid #004B8D !important;
  }
  .invite_inputContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .invite_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;
  }
  .invite_row .checkBox_container{
    width: 9vw;
  }
  .invite_row .simple_btn{
    width: 13.3vw;
  }
  
  .forget_password_container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
  }
  @media screen and (max-width: 768px) {
    .inviteHeader{
      justify-content: center;
      padding-top: 25px;
    }
    .inviteContainer{
      margin-top: 120px;
      margin-bottom: 155px;
    }
    .invite_tabsContainer{
      margin-bottom: 30px;
    }
    .invite_size {
        width: 100%;
    }
    .invite_tab{
      padding: 10px 0;
      font-size: 20px;
      line-height: 24px;
    }
    .invite_row .simple_btn{
      width: 185px;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 0;
    }
    .invite_row{
      margin-bottom: 15px;
    }
    .invite_row .checkBox_container {
        width: 75px;
    }
    .invTabCont{
      width: 100%;
    }
    .forget_password_container{
      justify-content: flex-end;
    }
  }